<script setup lang="ts">
// This component is a thin wrapper around BaseHeading to make sure we only
// use a few different title styles in our app, and makes it easier to change them
// if necessary.

// Right now we have omly two title sizes / styles.
// Large is used for content pages. Default for all others.
type TitleStyle = 'large' | 'default'

const props = withDefaults(defineProps<{ titleStyle?: TitleStyle }>(), {
  titleStyle: 'default',
})

const size = computed(() => {
  switch (props.titleStyle) {
    case 'large':
      return '5xl'
    default:
      return 'xl'
  }
})
</script>

<template>
  <BaseHeading :size="size" as="h2">
    <slot />
  </BaseHeading>
</template>
